<storybook-yes-no-dialog
  [showCloseButton]="true"
  [title]="data.title || ''"
  [appearance]="data.dialogAppearence || ''"
  [buttonSize]="data.buttonSizing || ''"
  [message]="data.message || ''"
  [confirmLabel]="data.confirmLabel || ''"
  [cancelLabel]="data.cancelLabel || ''"
  (onCancel)="onCancel.emit($event)"
  (onConfirm)="onConfirm.emit($event)"
  [hideCancelButton]="data.hideCancel">
</storybook-yes-no-dialog>
