import { Injectable } from '@angular/core';
import { UserInfoModel } from '@vr/vr-storybook-lib';
import { Amplify, Auth } from 'aws-amplify';

import { environment } from 'src/environments/environment';
import { JwtDecoderService } from './jwt-decoder.service';

const aws_settings = {
	common: {
		aws_cognito_region: 'eu-west-1',
		aws_cognito_idp_name: environment.cognito.idpName,
		aws_user_pools_id: environment.cognito.userPoolId,
		aws_user_pools_web_client_id: environment.cognito.userPoolWebClientId,
		federationTarget: 'COGNITO_USER_POOLS',
		oauth: {
			domain: environment.cognito.domain,
			scope: ['openid'],
			redirectSignIn: environment.cognito.loginUrl,
			redirectSignOut: environment.cognito.logoutUrl,
			responseType: 'code',
		},
	},
};

export function getAwsSettings() {
	const settings = {
		...aws_settings.common,
	};
	return settings;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private jwtDecoderService: JwtDecoderService) {
		Amplify.configure(getAwsSettings());
	}

	public async login(): Promise<any> {
		Auth.federatedSignIn({
			customProvider: environment.cognito.idpName,
			customState: JSON.stringify({dynamicRedirectUrl : '/'}),
		});
	}

	public async validateAccessByToken(): Promise<boolean> {
		let isLoggedIn = false;
		try {
			const session = await Auth.currentSession();
			isLoggedIn = session.isValid();
		} catch (err) {
			isLoggedIn = false;
		}
		return isLoggedIn;
	}

	public async GetCurrentUser(): Promise<UserInfoModel> {
		const session = await Auth.currentSession();
		const token = session.getIdToken().getJwtToken();
		const currUser = this.jwtDecoderService.decode(token);
		const user: UserInfoModel = {
			emailLabel: 'Email',
			emailContent: currUser.email,
			name: currUser.given_name + ' ' + currUser.family_name,
			roleLabel: 'Roles',
			roleContent: JSON.parse(currUser['custom:roles']),
			logoutLabel: 'Log out',
		};
		return user;
	}

	public async getToken() {
		const session = await Auth.currentSession();
		const token = session.getAccessToken().getJwtToken();
		return token;
	}

	public logout() {
		Auth.signOut();
	}
}
