import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonSizeV2, VrStorybookLibModule } from '@vr/vr-storybook-lib';
import { DialogAppearance, ItemsType } from '@vr/vr-storybook-lib';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  standalone: true,
  imports: [VrStorybookLibModule],
})
export class DialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirmLabel: string;
      cancelLabel?: string;
      buttonSizing: ButtonSizeV2;
      dialogAppearence: DialogAppearance;
      table?: ItemsType[];
      hideCancel: boolean;
    }
  ) {}

  @Output()
  onConfirm = new EventEmitter<Event>();

  @Output()
  onCancel = new EventEmitter<Event>();
}
